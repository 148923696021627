import { AuthAnalyticProps, SaveUserJourneyAnalyticsSubmit } from '@interflora/ui-components/build/common/props'
import AnalyticsContext from 'context/AnalyticsContext'
import { useRouter } from 'next/router'
import { useContext } from 'react'

/** Custom hook for saving the user journey analytics. */
export const useSaveAuthAnalytics = () => {
  const analytics = useContext(AnalyticsContext)
  const router = useRouter()
  const pageUrl = router?.asPath?.split('?')[0]
  const saveUserJourneyAnalytics: SaveUserJourneyAnalyticsSubmit<AuthAnalyticProps> = (values) => {
    const { isSuccessful = false, journeyType } = values
    switch (journeyType) {
      case 'GUEST':
        analytics.continueAsGuest()
        break
      case 'LOGIN':
        analytics.signInJourney(isSuccessful, pageUrl)
        break
      case 'REGISTER':
        analytics.createAnAccount(isSuccessful)
        break
      default:
        break
    }
  }
  return { saveUserJourneyAnalytics }
}
