import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Configure } from 'react-instantsearch'
import AlgoliaWrapper from '../AlgoliaWrapper/AlgoliaWrapper'
import ProductListingWrapper from './ProductListingWrapper/ProductListingWrapper'
import {
  AlgoliaQueryType,
  LinkProps,
  MobileImage,
  Image as ImageProps,
} from '@interflora/ui-components/build/common/props'
import { removeSessionItems, SiteContext } from '@interflora/ui-components/build/utils/common'
import { SpotlightBannerBackgroundColor } from '@interflora/ui-components/build/styles/jss/utils'
import AnalyticsContext from 'context/AnalyticsContext'
import { useMediaQuery } from 'react-responsive'
import { useFeatureFlagExperiment } from '@interflora/ui-components/build/utils/useExperiment'
import { useRouter } from 'next/router'
import { CustomPaginationProvider } from '@interflora/ui-components/build/utils/CustomPaginationContext'
import { flag_category_page_initial_results_count } from '@interflora/ui-components/build/utils/featureFlags'
import { useTheme } from '@mui/material'
const removeSessionList = [
  'isFromPDP',
  'productScrollIntoView',
  'loadAll',
  'sessionFilters',
  'sessionCurrentPageSize',
  'sessionRefinementList',
  'sessionNumericMenu',
  'categoryName',
  'sortBy',
]

interface Props {
  category?: {
    id: string
    key: string
    name: string
    isInternational: boolean
  }
  uiType?: string
  meta?: {
    deliveryId: string
  }
  spotlightBanner?: {
    body?: string
    backgroungImage?: ImageProps
    mobileBackgroundImage?: MobileImage
    transparentOverlay?: number
    title?: string
    backgroundColor?: SpotlightBannerBackgroundColor
    bannerLink?: string
    buttonLink?: LinkProps
    desktopPosition?: number
    mobilePosition?: number
  }
  facetDetails?: (name: string, value: string) => void
  sendSpotlightBannerAnalytics?: (click_text: string) => void
  onLoaded?: () => void
}

type FilterState = {
  filters: string
  query: string
  queryType?: AlgoliaQueryType
  pageSize: number
  initialUiState: object
  scrollTo: string
  sortBy: string
  page: number
}

const emptyFilterState = {
  filters: '',
  query: '',
  pageSize: 12,
  initialUiState: {},
  scrollTo: '',
  sortBy: '',
  page: 0,
}

/**
 * Create the default index name for product searches, with optional international and ranking parts
 */
export const useDefaultIndexName = (isInternational?: boolean) => {
  const { shorthand } = useContext(SiteContext)
  const envPrefix = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX
  const indexSuffix = isInternational ? 'intl_' : ''
  return `${envPrefix}_product_${indexSuffix}${shorthand}`
}

/**
 * Product Listing Component for Category/PLP
 *
 * Doesn't render until the filter state has been set for the first time as its driven from changes in the router which is not always ready on first render
 * Uses a key of the filter state so the Algolia search is recreated each time it changes, clearing down the facets, as clearing them manually can cause two queries to happen
 * flashing the wrong results on the screen and triggering two analytic events
 */

const ProductListing = ({ category, meta, uiType, spotlightBanner, onLoaded }: Props) => {
  // Feature flags
  const resultsCountVariant = useFeatureFlagExperiment(
    flag_category_page_initial_results_count.key,
    typeof window !== 'undefined' && window.location.pathname.startsWith('/category/')
  )
  const explicitCountVariant = resultsCountVariant === flag_category_page_initial_results_count.explicit_count

  const router = useRouter()
  const theme = useTheme()
  const isMobileDevice = !useMediaQuery({ query: `(min-width: ${theme.breakpoints.values.sm}px)` })
  const previousCategoryKey = useRef('')
  const analytics = useContext(AnalyticsContext)
  const [filters, setFilters] = useState<FilterState>(null)
  const defaultIndex = useDefaultIndexName(category?.isInternational)
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const sortConfig = useMemo(
    () => [
      { value: `${defaultIndex}`, label: 'Recommended' },
      { value: `${defaultIndex}_price_asc`, label: 'Price low - high' },
      { value: `${defaultIndex}_price_desc`, label: 'Price high - low' },
    ],
    [defaultIndex]
  )

  const handleSelectFacet = useCallback(
    (name: string, value: string) => {
      if (name == 'sortBy') {
        analytics.facetSortBy(value)
      } else {
        analytics.facetFilters(name, value)
      }
    },
    [analytics]
  )

  const initialPageSize = explicitCountVariant ? (!isMobileDevice ? 40 : 20) : 12

  const getUpdatedFilterState = useCallback(() => {
    if (category?.key && previousCategoryKey?.current !== category?.key && router.isReady) {
      previousCategoryKey.current = category?.key
      const filterState: FilterState = {
        ...emptyFilterState,
        queryType: 'PLP',
        filters: `allCategories:${category.key}`,
        pageSize: initialPageSize,
      }
      console.log(filterState)
      return filterState
    }
    return null
  }, [category.key, initialPageSize, router.isReady])

  const isLoading = useRef(true)
  useEffect(() => {
    // Update category filters and reset sortBy each time category changes
    const updatedFilterState = getUpdatedFilterState()

    // Apply params from url and session storage only on first component load
    if (isLoading.current && router.isReady) {
      isLoading.current = false

      // Load page number on first load
      const pageParam = parseInt(router.query['page']?.toString() || '0')
      const page = pageParam > 0 ? pageParam - 1 : 0

      // Only use session params if the category name matches
      if (category?.name === sessionStorage?.getItem('categoryName')) {
        const prevPageSize = parseInt(sessionStorage?.getItem('sessionCurrentPageSize'))
        const prevRefinementList = sessionStorage?.getItem('sessionRefinementList')
        const prevNumericMenu = sessionStorage?.getItem('sessionNumericMenu')
        const prevSortBy = sessionStorage?.getItem('sortBy')
        const scrollTo = sessionStorage?.getItem('productScrollIntoView')

        const pageSize = prevPageSize ? prevPageSize : initialPageSize
        const sortBy = prevSortBy || defaultIndex
        const refinementList = (prevRefinementList && JSON.parse(prevRefinementList)) || undefined
        const numericMenu = (prevNumericMenu && JSON.parse(prevNumericMenu)) || undefined

        // On page load, set the initial values for the search from the url
        const initialUiState = {
          ...(sortBy && { sortBy }),
          ...(refinementList && { refinementList }),
          ...(numericMenu && { numericMenu }),
          ...(page && { page }),
        }
        setFilters({ ...updatedFilterState, initialUiState, page, pageSize, scrollTo })
      } else {
        // If the category name does not match the session data then just use the initial page size
        setFilters({ ...updatedFilterState, page, pageSize: initialPageSize })
      }

      // Whether session data is used or not, remove the data now we are back on the PLP
      removeSessionItems(removeSessionList)
    } else {
      updatedFilterState && setFilters(updatedFilterState)
    }
  }, [
    category.key,
    category?.name,
    defaultIndex,
    getUpdatedFilterState,
    initialPageSize,
    isMobileDevice,
    router.isReady,
    router.query,
  ])

  /** saving category spotlight banner analytics */
  const sendSpotlightBannerAnalytics = useCallback(
    (clickText: string) => {
      analytics?.categorySpotLightBanner(clickText, category?.name)
    },
    [analytics, category?.name]
  )

  const handleChangePageSize = (pageSize: number) => setFilters((prev) => ({ ...prev, pageSize }))
  return (
    <div style={{ minHeight: showSkeleton && !isMobileDevice ? '100vh' : 'unset' }}>
      {filters && resultsCountVariant && (
        <AlgoliaWrapper indexName={defaultIndex} initialUiState={filters?.initialUiState} key={filters?.filters || ''}>
          <Configure
            clickAnalytics
            distinct
            filters={filters?.filters || ''}
            hitsPerPage={filters?.pageSize}
            page={filters?.page}
          />
          <CustomPaginationProvider length={filters?.pageSize} setLength={handleChangePageSize}>
            <ProductListingWrapper
              componentId={meta.deliveryId}
              componentType={uiType}
              sortConfig={sortConfig}
              queryType={filters?.queryType}
              categoryName={category?.name}
              spotlightBanner={spotlightBanner}
              facetDetails={handleSelectFacet}
              sendSpotlightBannerAnalytics={sendSpotlightBannerAnalytics}
              scrollTo={filters?.scrollTo}
              onLoaded={onLoaded}
              showSkeleton={!isMobileDevice ? showSkeleton : false}
              setShowSkeleton={setShowSkeleton}
            />
          </CustomPaginationProvider>
        </AlgoliaWrapper>
      )}
    </div>
  )
}

export default ProductListing
