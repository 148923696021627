/* eslint-disable @next/next/no-sync-scripts */
import Script from 'next/script'
import React from 'react'

/*
 * DO NOT CHANGE THIS CODE WITHOUT GOOD REASON!
 *
 * The functions exported here are responsible for rendering out the
 * OneTrust Cookies Consent Notice tags as defined in the following documentation:
 * https://my.onetrust.com/s/topic/0TO1Q000000ItRyWAK/cookie-compliance
 *
 * Also using Auto Blocking which require scripts to be loaded first and synchronusly
 * https://my.onetrust.com/s/article/UUID-c5122557-2070-65cb-2612-f2752c0cc4aa?topicId=0TO1Q000000ssJBWAY
 *
 * The container ID is supplied by the build process and is specific to each environment:
 * https://app-eu.onetrust.com/cookies/websites
 *
 */
export function renderOneTrustTags() {
  const oneTrustId = getOneTrustId()
  return (
    oneTrustId && (
      <>
        <Script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={oneTrustId}
        />
        <Script
          id="oneTrust"
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
        />
      </>
    )
  )
}

/**
 * Get the One Trust ID. This should always be available in the
 * sever-side code as `process.env.ONE_TRUST_ID`, but may also be required when
 * events are fired from the client-side code, where it can be found in
 * `process.env.NEXT_PUBLIC_ONE_TRUST_ID`
 */
export function getOneTrustId() {
  return process.env.ONE_TRUST_ID || process.env.NEXT_PUBLIC_ONE_TRUST_ID || '4f9efdeb-ef19-4529-b1f6-0dfc1e989fbf-test'
}
