import {
  AlgoliaQueryType,
  ProductData,
  Image as ImageProps,
  LinkProps,
} from '@interflora/ui-components/build/common/props'
import ProductListingUI from '@interflora/ui-components/build/components/ProductListing/ProductListing'
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch'
import AnalyticsContext from '../../../context/AnalyticsContext'
import { SpotlightBannerBackgroundColor } from '@interflora/ui-components/build/styles/jss/utils'
import { useLoaded } from '@interflora/ui-components/build/components/MainContent/useComponentLoading'
import { isEmpty } from 'lodash'
// commenting for next release
// import aa from 'search-insights'

type Props = {
  componentId?: string
  componentType?: string
  sortConfig: {
    value: string
    label: string
  }[]
  queryType?: AlgoliaQueryType
  categoryName?: string
  spotlightBanner?: {
    body?: string
    backgroungImage?: ImageProps
    transparentOverlay?: number
    title?: string
    backgroundColor?: SpotlightBannerBackgroundColor
    bannerLink?: string
    buttonLink?: LinkProps
    desktopPosition?: number
    mobilePosition?: number
  }
  facetDetails?: (name: string, value: string) => void
  sendSpotlightBannerAnalytics?: (clickText: string) => void
  scrollTo?: string
  onLoaded?: () => void
  showSkeleton?: boolean
  setShowSkeleton?: (value: boolean) => void
}

type ListingAnalyticsProps = {
  componentId: string
  componentType: string
  queryType?: AlgoliaQueryType
}
// commenting for next release
// aa('init', {
//   appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
//   apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
//   useCookie: true,
// })

/**
 * Component to call the analytics event once the results are ready
 */
const ListingAnalytics = (props: ListingAnalyticsProps) => {
  const analytics = useContext(AnalyticsContext)
  const instantSearch = useInstantSearch()
  const { results: searchResults, indexUiState: searchState, status } = instantSearch
  const isSearchLoaded = status === 'idle' && !!searchState.configure
  const { componentId, componentType, queryType } = props
  const index = searchResults?.index
  const searchResultsCopy = searchResults as any

  const keysRef = useRef<string>()
  const keys = searchResults?.hits.map((hit) => hit.key).join(',')
  useEffect(() => {
    if (isSearchLoaded && keys !== keysRef.current) {
      keysRef.current = keys
      const products = searchResults?.hits.map((hit) => hit.data).filter(Boolean)
      analytics.viewProductList({ id: componentId, name: componentType, products })
    }
  }, [analytics, componentId, componentType, isSearchLoaded, keys, searchResults?.hits])

  const viewedFilterRef = useRef<string>()
  useEffect(() => {
    // See if category filter has changed and trigger view event
    const filters = searchState?.configure?.filters
    if (searchState && index && filters !== viewedFilterRef.current) {
      viewedFilterRef.current = filters
      if (filters) {
        analytics.algoliaViewedFilters(
          index,
          filters,
          queryType,
          `${searchResultsCopy?.abTestID}`,
          `${searchResultsCopy?.abTestVariantID}`
        )
        // commenting for next release
        // aa('viewedFilters', {
        //   index,
        //   eventName: 'IF Filter Viewed',
        //   filters: [filters],
        // })
      }
    }
  }, [analytics, index, searchState, queryType])

  const clickedFiltersRef = useRef<string[]>()
  useEffect(() => {
    if (searchState && index) {
      // See if refinements or multi ranges have changed and trigger click event for any additions

      // convert refinement list from {"occasions":["I'm Sorry", "Birthday"]} to ["occasions:I'm Sorry","occasions:Birthday"]
      const refinementList = searchState?.refinementList || {}
      const refinementFilters = Object.keys(refinementList).reduce(
        (filters: string[], key) =>
          refinementList[key] ? [...filters, ...refinementList[key].map((value) => `${key}:${value}`)] : filters,
        []
      )

      const filters = refinementFilters // [...refinementFilters, ...rangeFilters]
      // Only send the added filters
      const addedFilters = filters.filter((value) => !clickedFiltersRef.current?.some((previous) => value === previous))
      if (addedFilters.length > 0) {
        analytics.algoliaClickedFilters(
          index,
          addedFilters.join(','),
          queryType,
          `${searchResultsCopy?.abTestID}`,
          `${searchResultsCopy?.abTestVariantID}`
        )
        // commenting for next release
        // aa('clickedFilters', {
        //   index,
        //   eventName: 'IF Filter Clicked',
        //   filters: [addedFilters.join(',')],
        // })
      }
      // update the filters so we dont send events for any of them again
      clickedFiltersRef.current = filters
    }
  }, [analytics, index, searchState, queryType])

  return null
}

const ListingWrapper = (props: Props) => {
  const {
    componentId,
    componentType,
    sortConfig,
    queryType,
    categoryName,
    spotlightBanner,
    facetDetails,
    sendSpotlightBannerAnalytics,
    scrollTo,
    showSkeleton,
    setShowSkeleton,
  } = props
  const { items, results } = useInfiniteHits()
  const { indexUiState, status } = useInstantSearch()
  const { index: indexName, hitsPerPage, page } = results
  const refinementList = JSON.stringify(indexUiState.refinementList || {})
  const numericMenu = JSON.stringify(indexUiState.numericMenu || {})
  const keys = items.map((hit) => hit.key).join(',')
  const products = useMemo(
    () =>
      items
        .map(
          (hit): ProductData => ({
            ...hit.data,
            objectId: hit.objectID,
            position: hit.__position,
            queryId: hit.__queryID,
            queryType,
            indexName,
          })
        )
        .filter(Boolean),
    // items array is recreated too often, update when the product keys change instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keys, queryType, indexName]
  )

  // To prevent rerenders store the data that will be stored in the session on card click in a ref
  // and use a callback for the handler function that doesn't update
  const sessionStorageToSave = useRef({})
  useEffect(() => {
    sessionStorageToSave.current = {
      isFromPLP: true,
      categoryName,
      sortBy: indexName,
      sessionCurrentPageNo: page,
      sessionCurrentPageSize: hitsPerPage,
      sessionRefinementList: refinementList,
      sessionNumericMenu: numericMenu,
    }
  }, [categoryName, hitsPerPage, indexName, numericMenu, page, refinementList])

  const handleProductClick = useCallback((id: string) => {
    sessionStorage.setItem('productScrollIntoView', id)
    Object.entries(sessionStorageToSave.current)
      .filter((entry) => !!entry[1])
      .forEach(([key, value]) => {
        sessionStorage.setItem(key, value.toString())
      })
  }, [])

  const scrolled = useRef(false)
  useEffect(() => {
    if (!scrolled.current && scrollTo && products) {
      setTimeout(() => {
        const productCard = document.getElementById(scrollTo)
        productCard ? productCard.scrollIntoView() : window.scrollTo(0, 0)
        scrolled.current = true
      }, 500)
    }
  }, [products, scrollTo])
  useEffect(() => {
    if (!isEmpty(indexUiState) && status === 'idle' && isEmpty(products) && showSkeleton && setShowSkeleton) {
      setShowSkeleton(false)
    }
  }, [status, indexUiState])

  useLoaded(props, status === 'idle' && !!indexUiState.configure)

  return (
    <>
      <ListingAnalytics componentId={componentId} componentType={componentType} queryType={queryType} />
      <ProductListingUI
        products={products}
        sortConfig={sortConfig}
        categoryName={categoryName}
        data-hj-ignore-attributes
        spotlightBanner={spotlightBanner}
        facetDetails={facetDetails}
        sendSpotlightBannerAnalytics={sendSpotlightBannerAnalytics}
        onProductClick={handleProductClick}
        showSkeleton={showSkeleton}
        setShowSkeleton={setShowSkeleton}
      />
    </>
  )
}

export default ListingWrapper
